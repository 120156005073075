import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(AclInstaller)

let initialRole = 'guest'

const userInfo = localStorage.getItem('accessToken') ? VueJwtDecode.decode(localStorage.getItem('accessToken')) : {}
if (userInfo && userInfo[`${process.env.VUE_APP_API_URL}/role`] && userInfo[`${process.env.VUE_APP_API_URL}/role`][0]) initialRole = userInfo[`${process.env.VUE_APP_API_URL}/role`][0]

export default new AclCreate({
  initial  : initialRole,
  notfound : '/pages/not-authorized',
  router,
  acceptLocalRules : true,
  globalRules: {
    admin: new AclRule('admin').generate(),
    merchant: new AclRule('merchant').generate(),
    //New Rules
    headOfFleet: new AclRule('head-of-fleet').generate(),
    merchantAgent: new AclRule('merchant-agent').generate(),
    customerAgent: new AclRule('customer-agent').generate(),
    manager: new AclRule('manager').generate(),
    transporter: new AclRule('transporter').generate(),
    customerSupport: new AclRule('customer-support').generate(),
    finance: new AclRule('finance').generate(),
    growthHacker: new AclRule('growth-hacker').generate(),
    sales: new AclRule('sales').generate(),
    fcOperationsSupervior: new AclRule('fc-operations-supervisor').generate(),
    fcReceivingAgent: new AclRule('fc-receiving-agent').generate(),
    fcInboundingAgent: new AclRule('fc-inbounding-agent').generate(),
    fcStoringAgent: new AclRule('fc-storing-agent').generate(),
    fcPackingAgent: new AclRule('fc-packing-agent').generate(),
    fcPickingAgent: new AclRule('fc-picking-agent').generate(),
    opsAdmin: new AclRule('ops-admin').generate(),
    commercialAdmin: new AclRule('commercial-admin').generate(),
    guest: new AclRule('guest').or('admin').or('merchant').or('manager').or('transporter').or('head-of-fleet').or('merchant-agent').or('customer-agent').or('customer-support').or('finance').or('growth-hacker').or('sales').or('fc-operations-supervisor').or('fc-receiving-agent').or('fc-inbounding-agent').or('fc-storing-agent').or('fc-picking-agent').or('fc-packing-agent').or('ops-admin').or('commercial-admin').generate()
  }
})
