/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import auth from '@/auth/authService'

export default {
  isUserLoggedIn: () => {
    let isAuthenticated = false

    if (auth.isAuthenticated()) isAuthenticated = true
    else isAuthenticated = false

    return isAuthenticated
  }
}
