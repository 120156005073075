import auth0 from 'auth0-js'
import EventEmitter from 'events'

import store from '@/store/store.js'

import Vue from 'vue'
Vue.use(require('vue-cookies'))

const tokenExpiryKey = 'tokenExpiry'
const loginEvent = 'loginEvent'

const webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  redirectUri: `${window.location.origin}/${Vue.$cookies.get('language') ? Vue.$cookies.get('language') : 'ar'}/auth-callback`,
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  responseType: 'code token id_token',
  scope: 'openid profile email'
})

class AuthService extends EventEmitter {
    idToken = null;
    profile = null;
    tokenExpiry = null;

    // Starts the user login flow
    login (customState) {
      webAuth.authorize({
        appState: customState
      })
    }

    // Handles the callback request from Auth0
    handleAuthentication () {
      return new Promise((resolve, reject) => {
        webAuth.parseHash((err, authResult) => {
          if (err) {
            alert(`${err.error}. Detailed error can be found in console.`)
            reject(err)
          } else {
            this.localLogin(authResult)
            resolve(authResult.idToken)
          }
        })
      })
    }

    localLogin (authResult) {
      if (authResult) {
        this.idToken = authResult.accessToken
        this.profile = authResult.idTokenPayload
        const tokenPayload = {
          picture: this.profile.picture,
          name: this.profile.name,
          email:  this.profile.email
        }
        // Convert the JWT expiry time from seconds to milliseconds
        this.tokenExpiry = Date.now() + (authResult.expiresIn * 1000)
        localStorage.setItem(tokenExpiryKey, this.tokenExpiry)
        localStorage.setItem('accessToken', this.idToken)
        localStorage.setItem('idTokenPayload', JSON.stringify(tokenPayload))

        store.commit('UPDATE_USER_INFO', {
          displayName: this.profile.name,
          email: this.profile.email,
          photoURL: this.profile.picture,
          providerId: this.profile.sub.substr(0, this.profile.sub.indexOf('|')),
          uid: this.profile.sub,
          userRole: this.profile[`${process.env.VUE_APP_API_URL}/role`][0],
          idToken: this.idToken
        })

        this.emit(loginEvent, {
          loggedIn: true,
          profile: authResult.idTokenPayload,
          state: authResult.appState || {}
        })
      }
    }

    renewTokens () {
      webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          return false
        } else {
          this.localLogin(authResult)
          return true
        }
      })
    }

    logOut () {
      localStorage.removeItem(tokenExpiryKey)
      localStorage.removeItem('createdStockRequests')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('loggedIn')

      this.idToken = null
      this.tokenExpiry = null
      this.profile = null

      webAuth.logout({
        returnTo: window.location.origin + process.env.BASE_URL
      })

      this.emit(loginEvent, { loggedIn: false })
    }

    isAuthenticated () {
      if (Date.now() < localStorage.getItem(tokenExpiryKey)) {
        return true
      } else {
        return this.renewTokens()
      }
    }
}

export default new AuthService()
