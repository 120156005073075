import axios from 'axios'

const token = localStorage.getItem('accessToken')
let header = {
  Authorization: `Bearer ${token}`
}
if (window.location.href.includes('sign-up')) {
  header = {}
}

export default axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: header
})
