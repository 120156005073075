/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'material-symbols' // Material Symbols
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
const firebaseApp = firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
})
firebaseApp.firestore()

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// Theme Configurations
import '../themeConfig.js'


// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)


// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'

// Vuexy Admin Filters
import './filters/filters'


// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

//MIXPANEL
import VueMixpanel from 'vue-mixpanel'
Vue.use(VueMixpanel, {
  token: process.env.VUE_APP_MIXPANEL_TOKEN,
  config: {
    debug: false
  }
})

// Scroll Loading
import ScrollLoader from 'vue-scroll-loader'
Vue.use(ScrollLoader)

// Feather font icon
require('./assets/css/iconfont.css')

import VueOnlinePlugin from 'vue-navigator-online'
Vue.use(VueOnlinePlugin)

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css'
Vue.use(require('vue-cookies'))

if (!Vue.$cookies.get('tourMerchantDashboard')) {
  Vue.$cookies.set('tourMerchantDashboard', false)
  Vue.$cookies.set('tourMerchantOrders', false)
  Vue.$cookies.set('reloaded', false)
}
localStorage.setItem('APIs-cache', JSON.stringify([]))

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import './registerServiceWorker'

if (process.env.VUE_APP_RELEASE_VERSION && process.env.VUE_APP_RELEASE_VERSION !== 'staging') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['app.shipblu.com']
      })
    ],
    tracesSampleRate: 0.005,
    ignoreErrors: ['vuesax', 'ChunkLoadError', 'chunk']
  })
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')
